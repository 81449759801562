import { useEffect, useState } from 'react'
import { genricImageLinks } from '../../constants'
import { COLORS } from '../../constants/colors'
import { HamBurgerIcon, IconLeftArrow } from '../../image'
import { HamburgerIconConatiner, HeaderMobileContainer, HeaderTitle, IconContainer, Link, SideDrawer, SideDrawerLinkContainer } from './HeaderMobile.style'
import { useHistory } from 'react-router'
import IntlMessages from '../utility/intlMessages'

function HeaderMobile ({
  headerLink = [],
  isHomePage = false,
  logout = () => { },
  userName = '',
  profileUrl = '',
  activeTab = '',
  logo = genricImageLinks.fallbackLogo,
  headerBackground = COLORS.tapPartnerYellow,
  navigationLinks = [],
  navigationClickHandler = () => { },
  isLoggedIn = false,
  selectedLanguage = '',
  changeLanguagehandler = () => { },
  profileRenderMenu = [],
  navigateToLink = () => { },
  headerTitle,
  showBackButton = false
}) {
  const history = useHistory()
  const [isHamBurgerOpen, setHamBurgerOpen] = useState(false)

  useEffect(() => {
    if (isHamBurgerOpen) {
      const bodyStyle = document.querySelector('body').style
      bodyStyle.overflow = 'hidden'
    } else {
      const bodyStyle = document.querySelector('body').style
      bodyStyle.overflow = 'auto'
    }
    return () => {
      const bodyStyle = document.querySelector('body').style
      bodyStyle.overflow = 'auto'
    }
  }, [isHamBurgerOpen])

  const openHamburgerIcon = (e) => {
    e.stopPropagation()
    setHamBurgerOpen((item) => !item)
  }

  const closeHamBurgerIcon = () => {
    setHamBurgerOpen(false)
  }

  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      <HeaderMobileContainer>
        {
          showBackButton &&
          <IconContainer>
            <img src={IconLeftArrow} onClick={goBack} />
          </IconContainer>
        }
        <HeaderTitle>
          <IntlMessages id={headerTitle || ' '} />
        </HeaderTitle>
        <HamburgerIconConatiner onClick={openHamburgerIcon}>
          <img loading='lazy' src={HamBurgerIcon} alt='hamburger-icon' height={20} width={20} />
        </HamburgerIconConatiner>
      </HeaderMobileContainer>
      {
        isHamBurgerOpen
          ? (
            <SideDrawer onClick={closeHamBurgerIcon}>
              <SideDrawerLinkContainer>
                {
                  headerLink.map(({ title, link, name, action, render, closeAtAction }) => {
                    return (
                      <Link
                        key={title}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (action) {
                            action()
                            if (closeAtAction) closeHamBurgerIcon()
                            return
                          }
                          closeHamBurgerIcon()
                          navigateToLink({ e, link, name, action })
                        }
                        }>
                        <IntlMessages id={title || ' '} />
                        {render && render()}
                      </Link>
                    )
                  })
                }
              </SideDrawerLinkContainer>
            </SideDrawer>
          )
          : null
      }
    </>
  )
}

export default HeaderMobile
